import React from "react"
import createHtml from "../../helpers/createHtml"

import ContinueLink from "../ContinueLink"

import styles from "./TextBlock.module.scss"

const TextBlock = ({
  className,
  text,
  link,
  linkText,
  contentClass,
  linkClass,
  markdowndData,
}) => (
  <div className={`${styles.textBlock} ${className}`}>
    <div className={`${styles.textBlock__content} ${contentClass}`}>
      {markdowndData ? (
        <div
          className={styles.textBlock__text}
          dangerouslySetInnerHTML={createHtml(markdowndData)}
        />
      ) : (
        <div className={styles.textBlock__text}>{text}</div>
      )}

      {link && (
        <ContinueLink to={link} label={linkText} className={linkClass} />
      )}
    </div>
  </div>
)

export default TextBlock
