import React from "react"
import createHtml from "../../helpers/createHtml"

import styles from "./TitleBlock.module.scss"

const TitleBlock = ({
  className,
  miniTitle,
  title,
  image,
  children,
  markdowndData,
}) => {
  const mainClass = className
    ? `${styles.titleBlock} ${className}`
    : styles.titleBlock

  return (
    <div className={mainClass}>
      <div className={styles.titleBlock__column}>
        <div className={styles.titleBlock__miniTitle}>{miniTitle}</div>
        <div className={styles.titleBlock__mainTitle}>{title}</div>
        {markdowndData ? (
          <div dangerouslySetInnerHTML={createHtml(markdowndData)} />
        ) : (
          <div>{children}</div>
        )}
      </div>
      <div
        className={`${styles.titleBlock__column} ${styles.titleBlock__column__withImage}`}
      >
        {image}
      </div>
    </div>
  )
}

export default TitleBlock
