import React, { useMemo } from "react"

import Layout from "../../components/Layout"
import TextBlock from "../../components/TextBlock/TextBlock"
import TitleBlock from "../../components/TitleBlock"
import HorLine from "../../components/HorLine"

import "../../styles/global.scss"
import stylesSuperpowers from "./our-superpowers.module.scss"

import SuperpowersBlockImg from "../../assets/images/pages/home/SuperpowersBlockImg.svg"
import CloudUpload from "../../assets/images/pages/ourSuperpowers/cloudUpload.svg"
import Automation from "../../assets/images/pages/ourSuperpowers/automation.svg"
import Integration from "../../assets/images/pages/ourSuperpowers/integration.svg"
import Lock from "../../assets/images/pages/ourSuperpowers/lock.svg"
import { remark } from "remark"
import { graphql, useStaticQuery } from "gatsby"
import remarkHtml from "remark-html"
import createHtml from "../../helpers/createHtml"
import findLangIndex from "../../helpers/findLangIndex";
import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage"

const Superpowers = () => {
  const language = useGetBrowserLanguage()

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query OurSuperPowersPageData {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(our-superpowers)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              mini_title
              title
              title_description
              main_text
              clouds_title
              clouds_description
              it_security_title
              it_security_description
              system_integration_title
              system_integration_description
              section_description
            }
          }
        }
      }
    }
  `)

  const remarkObj = useMemo(() => remark().use(remarkHtml), [])

  const pageData = useMemo(() => {
    const langIdxEn = findLangIndex(allMarkdownRemark?.edges, "our-superpowers/our-superpowers.md", "en");

    return {
      en: {
        miniTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.mini_title,
        title: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title,
        titleDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title_description
          )
          .toString(),
        mainText: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.main_text,
        cloudTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.clouds_title,
        cloudDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.clouds_description
          )
          .toString(),
        itSecurityTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.it_security_title,
        itSecurity: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter
              ?.it_security_description
          )
          .toString(),
        sectionDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter
              ?.section_description
          )
          .toString(),
        systemIntegrationTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.system_integration_title,
        systemIntegration: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter
              ?.system_integration_description
          )
          .toString(),
      }
    }
  }, [remarkObj, allMarkdownRemark])

  return (
    <Layout>
      <TitleBlock
        className={stylesSuperpowers.titleBlock}
        miniTitle={pageData?.en?.miniTitle}
        title={pageData?.en?.title}
        image={
          <SuperpowersBlockImg
            className={stylesSuperpowers.titleBlock__mainImg}
          />
        }
      >
      <div
        dangerouslySetInnerHTML={createHtml(pageData?.en?.titleDescription)}
      />
      </TitleBlock>
      
      <TextBlock
        className={stylesSuperpowers.aboutDynamist}
        text={pageData?.en?.mainText}
        markdowndData={pageData?.en?.sectionDescription}
      />

      <div className={stylesSuperpowers.automation}>
        <div className={stylesSuperpowers.automation__content}>
          <div className={stylesSuperpowers.automation__content__infoCard}>
            <div className={stylesSuperpowers.automation__content__infoCard__titleImg}>
              <CloudUpload />
            </div>
            <div>
              <div className={stylesSuperpowers.automation__content__infoCard__title}>
                {pageData?.en?.cloudTitle}
              </div>
              <div
                className={stylesSuperpowers.automation__content__infoCard__text}
                dangerouslySetInnerHTML={createHtml(pageData?.en?.cloudDescription)}
              />
            </div>
          </div>
          <div className={stylesSuperpowers.automation__content__image}>
            <Automation />
          </div>
        </div>
      </div>

      <div className={stylesSuperpowers.ourSuperpowersInfo}>
        <div className={stylesSuperpowers.ourSuperpowersInfo__content}>
          <div className={stylesSuperpowers.ourSuperpowersInfo__infoCard}>
            <div className={stylesSuperpowers.ourSuperpowersInfo__infoCard__category}>
              <Integration />
            </div>
            <div className={stylesSuperpowers.ourSuperpowersInfo__infoCard__title}>
              {pageData?.en?.systemIntegrationTitle}
            </div>
            <div
              className={stylesSuperpowers.ourSuperpowersInfo__infoCard__text}
              dangerouslySetInnerHTML={createHtml(pageData?.en?.systemIntegration)}
            />
          </div>
          <div className={stylesSuperpowers.ourSuperpowersInfo__infoCard}>
            <div className={stylesSuperpowers.ourSuperpowersInfo__infoCard__category}>
              <Lock />
            </div>
            <div className={stylesSuperpowers.ourSuperpowersInfo__infoCard__title}>
              {pageData?.en?.itSecurityTitle}
            </div>
            <div
              className={stylesSuperpowers.ourSuperpowersInfo__infoCard__text}
              dangerouslySetInnerHTML={createHtml(pageData?.en?.itSecurity)}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Superpowers
