import React from "react"
import { Link } from "gatsby"

import Svg from "../Svg"

import styles from "./ContinueLink.module.scss"

const ContinueLink = ({ className, label, to }) => (
  <Link className={`${styles.mainBox} ${className}`} to={to}>
    <span className={styles.mainBox__label}>{label}</span>
    <Svg.ShowMoreSolid />
  </Link>
)

export default ContinueLink
